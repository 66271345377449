import React from 'react';
import { Box, Text } from 'rebass';
import Container from './Container';

const Footer = () => (
  <Box as="footer" bg="darkgray" color="white">
    <Text
      as={Container}
      textAlign="center"
      pt={1}
      my={3}
      css="font-size: 0.65rem; opacity: 0.4;"
    >
      &copy; 2016
    </Text>
  </Box>
);

export default Footer;
