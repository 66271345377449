import { Heading, Link } from 'rebass';

const theme = {
  breakpoints: [600, 1024, 1440, 1920],
  colors: {
    red: '#f8485e',
    darkgray: '#3d3d3d',
  },
  space: [0, 4, 8, 16, 32, 64, 128, 256, 512].map(value => `${value / 16}rem`),
  fonts: {
    sans: '"Libre Franklin", system-ui, sans-serif',
    mono: '"Libre Franklin", monospace',
  },
  fontWeights: {
    bold: 400,
  },
  Heading: {
    fontWeight: 700,
  },
  Link: {
    textDecoration: 'none',
    'p &:hover': {
      borderBottom: '2px solid',
    },
  },
};

Heading.defaultProps = {
  ...Heading.defaultProps,
  as: 'h1',
  fontSize: '2.2rem',
  fontFamily: 'sans',
};

Link.defaultProps = {
  ...Link.defaultProps,
  color: '#f8485e',
};

export default theme;
