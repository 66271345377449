import { graphql, Link as GatsbyLink, StaticQuery } from 'gatsby';
import React from 'react';
import { Box, Flex } from 'rebass';
import Container from './Container';
import List from './List';
import ListItem from './ListItem';
import NavLink from './NavLink';

const Header = () => (
  <Box as="header" bg="darkgray" color="white">
    <Flex
      as={Container}
      flexDirection={['column', 'row']}
      justifyContent="space-between"
      alignItems="center"
      my={2}
    >
      <GatsbyLink
        to="/"
        css={`
          color: white;
          text-decoration: none;
          :not(:hover) {
            opacity: 0.75;
          }
          font-weight: 700;
        `}
      >
        Frank Harwood
      </GatsbyLink>

      <Box as="nav" my={2}>
        <List p={0} m={0}>
          <StaticQuery
            query={graphql`
              {
                allHeaderMenuItemsYaml {
                  edges {
                    node {
                      id
                      title
                      url
                    }
                  }
                }
              }
            `}
            render={data =>
              data.allHeaderMenuItemsYaml.edges.map(
                ({ node }: any, i: number) => (
                  <ListItem
                    key={node.id}
                    l
                    m={-2}
                    ml={i !== 0 ? [3, 4] : null}
                    css="display: inline-block;"
                  >
                    <NavLink as={GatsbyLink} to={node.url} p={2}>
                      {node.title}
                    </NavLink>
                  </ListItem>
                ),
              )
            }
          />
          <ListItem m={-2} ml={[3, 4]} css="display: inline-block;">
            <a
              href="http://portfoliofineart.com/artist/frank-hardwood"
              target="_blank"
              rel="noopener"
              css={`
                color: white;
                text-decoration: none;
                text-transform: uppercase;
                font-size: 0.8rem;
                box-sizing: border-box;
                padding: 0.5rem;
                :not(:hover) {
                  opacity: 0.75;
                }
              `}
            >
              Prints
            </a>
          </ListItem>
        </List>
      </Box>
    </Flex>
  </Box>
);

export default Header;
