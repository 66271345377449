import { graphql, StaticQuery } from 'gatsby';
import React from 'react';
import { Heading, Link, Text } from 'rebass';
import { Facebook } from 'styled-icons/fa-brands';
import { Email, ShoppingCart } from 'styled-icons/material';
import Container from './Container';
import IconLink from './IconLink';
import Paragraph from './Paragraph';
import Section, { Props } from './Section';

const ContactSection = (props: Props) => (
  <Section {...props}>
    <Container as={Text} textAlign="center">
      <Heading id="contact">Contact</Heading>

      <StaticQuery
        query={graphql`
          {
            site {
              siteMetadata {
                email
                siteShopURL
                siteFacebookURL
              }
            }
          }
        `}
        render={data => (
          <>
            <Text my={4} fontSize="2em">
              <IconLink
                icon={Facebook}
                href={data.site.siteMetadata.siteFacebookURL}
                title="Facebook"
              />

              <IconLink
                icon={Email}
                href={`mailto:${data.site.siteMetadata.email}`}
                title="Email"
                mx={4}
              />

              <IconLink
                icon={ShoppingCart}
                href={data.site.siteMetadata.siteShopURL}
                title="Buy Prints"
              />
            </Text>

            <Paragraph pb={2}>
              For enquiries and commissions, please email{' '}
              <Text
                as={Link}
                href={`mailto:${data.site.siteMetadata.email}`}
                fontWeight="bold"
              >
                Frank&nbsp;Harwood
              </Text>
              {''}.
            </Paragraph>
          </>
        )}
      />
    </Container>
  </Section>
);

export default ContactSection;
